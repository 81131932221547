
const ControlModal = async (element, boolStatus) => {
      let strStatus = boolStatus == true ? 'show' : 'hide'
      $('#' + element).modal(strStatus)
}

const DisplayLoading = (loader) => {

      loader.classList.add("display");

      setTimeout(() => {
            loader.classList.remove("display");
      }, 5000);
}

const HideLoading = (loader) => {
      loader.classList.remove("display");
}


const BtnCloseModal = (modalId) => {
      var btnId = document.querySelector("#modal-btn-n")
      btnId.addEventListener('click', function () {
            $('#' + modalId).modal('hide')
      })
}

const BtnForceCloseModal = (modalId) => {
      $('#' + modalId).modal('hide')
}

function startTimer(duration, display, labelBtn) {

      var timer = duration, minutes, seconds;

      const myInterval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.textContent = minutes + ":" + seconds;
            if (--timer < 0) {
                  //return
                  //timer = duration;
                  clearInterval(myInterval)
                  //var btnSubmit = document.querySelector("#btnExecuteApi")
                  display.removeAttribute('disabled', 'disabled');
                  display.textContent = labelBtn;
            }
      }, 1000);

}

const DisplayContadorRegressivo = (btnExecuteApi, labelBtn) => {

      //var btnSubmit = document.querySelector("#btnExecuteApi")
      //btnExecuteApi.setAttribute('disabled', 'disabled');
      var duration = 5; // Converter para segundos
      startTimer(duration, btnExecuteApi, labelBtn); // iniciando o timer
}

const BlockButtonExecuteApi = (btnExecuteApi) => {

      //btnExecuteApi.setAttribute('disabled', 'disabled');
}

const BodyLoading = (status) => {

      if (status) {
            $('#status').fadeIn(); // primeiro desaparecerá a animação de carregamento
            $('#preloader').delay(100).fadeIn('slow'); // desaparecerá o DIV branco que cobre o site.
            $('body').delay(100).css({ 'overflow': 'hidden' });
      }
      else {
            $('#status').fadeOut(); // primeiro desaparecerá a animação de carregamento
            $('#preloader').delay(100).fadeOut('slow'); // desaparecerá o DIV branco que cobre o site.
            $('body').delay(100).css({ 'overflow': 'visible' });

      }
}

const IdClickedInTable = (e) => {

      var id = e.target.value;

      if (e.target.id == "") {
            id = e.srcElement.parentElement.value
      }

      return id
}


const DateSort = (date) => {

      var dataReserva = date.split(' ');

      var datetime = dataReserva[0]
      var hourtime = dataReserva[1]

      var datetimeSplit = datetime.split('/');
      var dia = datetimeSplit[0]
      var mes = datetimeSplit[1]
      var ano = datetimeSplit[2]

      var hourtimeSplit = hourtime.split('/');
      var hora = hourtimeSplit[0]
      var minuto = hourtimeSplit[1]
      var segundo = hourtimeSplit[2]

      var dataSort = ano + mes + dia + hora + minuto + segundo

      return dataSort
}


export { ControlModal, DisplayLoading, HideLoading, BtnCloseModal, BtnForceCloseModal, DisplayContadorRegressivo, BlockButtonExecuteApi, BodyLoading, IdClickedInTable, DateSort }
