export const Session = {
      Usuario: localStorage.getItem('_usuario_logado') != null ? JSON.parse(localStorage.getItem('_usuario_logado')).nome : null,    
      VnoName: localStorage.getItem('_usuario_logado') != null ? JSON.parse(localStorage.getItem('_usuario_logado')).cliente.vnoName : null,
      VnoId: localStorage.getItem('_usuario_logado') != null ? JSON.parse(localStorage.getItem('_usuario_logado')).cliente.vnoId: null
  };
  


// export const Session = {
//     Usuario: "Usuário",    
//     VnoName: "X-COMPANY",
//     VnoId: "XPCO"
// };