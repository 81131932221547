

//export const getUrlBase = async () => {

      //LOCAL
      //return new Promise(new URL("https://localhost:7166/authLayer"));
      //const "https://localhost:7166/authLayer";
      //const UrlBase = new URL("https://localhost:7166/"); //LOCAL
      const UrlBase = new URL("https://authlayerapi.azurewebsites.net/"); //LOCAL


       //let VnoId = 0
      
      // console.log('usuario_logado')
      // console.log(localStorage.getItem('_usuario_logado'))

      // if (localStorage.getItem('_usuario_logado') != null) {
      //       VnoId = JSON.parse(localStorage.getItem('_usuario_logado')).cliente.vnoId      
      // }
      
      // console.log(VnoId)
      //HML
      //return "https://authlayerapi.azurewebsites.net" 
      //return new URL("https://authlayerapi.azurewebsites.net") ;
//}

export { UrlBase };